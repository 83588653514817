import { Component } from 'app/core/base-component'
import { ConsentModalService } from 'app/services/consent-modal.service'
import { ConsentModalTemplate } from './consent-modal.template'
import { ConsentModalEvent } from './consent-modal.model'
import { AppConfigService } from 'app/services/app-config.service'
import { ConsentWrapperService } from 'app/services/consent-wrapper.service'
import { ConsentWrapperStage } from 'app/models/consent-wrapper.model'

export class Modal extends Component {
  private readonly consentModalService: ConsentModalService
  private readonly consentWrapperService: ConsentWrapperService
  private readonly templateRef: ConsentModalTemplate
  private readonly visibilityToggleClass = 'visible'
  private readonly appConfigService: AppConfigService
  private customCssClassName = ''
  private displayCloseButton = false

  constructor() {
    super({
      componentName: 'consent-modal2',
      componentClass: 'consent-modal2'
    })

    this.consentModalService = ConsentModalService.getInstance()
    this.consentWrapperService = ConsentWrapperService.getInstance()
    this.appConfigService = AppConfigService.getInstance()
    this.templateRef = new ConsentModalTemplate()

    this.init()
  }

  show(): void {
    this.consentModalService.disablePageScrolling()
    this.addHostViewClass(this.visibilityToggleClass)
  }

  hide(): void {
    this.consentModalService.enablePageScrolling()
    this.consentWrapperService.switchStage(ConsentWrapperStage.General)

    if (this.consentModalService.getRefreshRequiredState()) {
      location.reload()
    } else {
      this.removeHostViewClass(this.visibilityToggleClass)
    }
  }

  setContent(element: HTMLElement): void {
    const mainContainer: HTMLElement | null = this.hostView.querySelector('[data-container="content"]')

    if (mainContainer != null) {
      mainContainer.appendChild(element)
    }
  }

  private init(): void {
    this.customCssClassName = this.appConfigService.getCustomCssClass()
    this.displayCloseButton = this.appConfigService.getDisplayCloseButtonState()

    if (this.customCssClassName?.length > 0) {
      this.addHostViewClass(this.customCssClassName)
    }

    this.addSubscriptions()
    this.renderContent(
      this.templateRef.getTemplate({
        displayCloseButton: this.displayCloseButton
      })
    )
  }

  private addSubscriptions(): void {
    this.consentModalService.hideModal$.subscribe(() => {
      this.hide()
    })

    this.templateRef.events[ConsentModalEvent.CloseModal].subscribe(() => {
      this.hide()
    })
  }
}
