import { BehaviorSubject } from 'rxjs'

import {
  type CookieConsent,
  type ApiCookieConsent,
  type CookieConsentCategory,
  type ConsentStorageData,
  type ConsentStorageDataApproval,
  type CookieConsentHistory
} from '../models/consent-data.model'
import { type ChangeAgreementEventData } from '../components/consent-details/consent-details.model'
import { ConsentStorageService } from './consent-storage.service'
import { UserService } from './user.service'
import { ConsentRequestService } from './consent-request.service'
import { ConsentModalService } from './consent-modal.service'
import { ConsentHistoryService } from './consent-history.service'
import { DomPreparationService } from './dom-preparation.service'
import { ApiService } from './api.service'
import { AppEventService } from './app-event.service'
import { GtagConsentService } from './gtag-consent.service'
import { AppConfigService } from './app-config.service'
import { deepCopy } from 'app/helpers/deep-copy'
import { areArraysOfObjectsEqual } from 'app/helpers/are-object-equal'

export class ConsentDataService {
  consentData$ = new BehaviorSubject<CookieConsent | null>(null)

  private static instance: ConsentDataService

  private apiConsentData!: ApiCookieConsent
  private normalizedConsentData!: CookieConsent
  private consentData!: CookieConsent
  private readonly consentStorageService: ConsentStorageService
  private readonly userService: UserService
  private readonly consentRequestService: ConsentRequestService
  private readonly consentModalService: ConsentModalService
  private readonly consentHistoryService: ConsentHistoryService
  private readonly domPreparationService: DomPreparationService
  private readonly appEventService: AppEventService
  private readonly apiService: ApiService
  private readonly gtagConsentService: GtagConsentService
  private readonly appConfigService: AppConfigService

  private constructor() {
    this.consentStorageService = ConsentStorageService.getInstance()
    this.userService = UserService.getInstance()
    this.consentRequestService = ConsentRequestService.getInstance()
    this.consentModalService = ConsentModalService.getInstance()
    this.consentHistoryService = ConsentHistoryService.getInstance()
    this.domPreparationService = DomPreparationService.getInstance()
    this.apiService = ApiService.getInstance()
    this.appEventService = AppEventService.getInstance()
    this.gtagConsentService = GtagConsentService.getInstance()
    this.appConfigService = AppConfigService.getInstance()
  }

  static getInstance(): ConsentDataService {
    if (ConsentDataService.instance === undefined) {
      ConsentDataService.instance = new ConsentDataService()
    }

    return ConsentDataService.instance
  }

  /**
   * Public API method: set cookie consent data
   */
  setInitialCookieConsentData(data: ApiCookieConsent): void {
    this.apiConsentData = this.getFilteredConsentData(data)
    this.normalizedConsentData = this.getNormalizeConsentData(this.apiConsentData)
    this.consentData = this.mergeApiDataWithSavedInStorageData(this.normalizedConsentData)
    this.consentData = this.unifyConsentItemsRequiring(this.consentData)
    this.updateConsentData()
    this.prepareForGtagIntegrationIfEnabled()
    this.domPreparationService.updateDomStructure(this.consentData.categories)
  }

  /**
   * Public API method: update cookie consent state by item tag
   */
  updateConsentStateByItemTag(tag: string, granted = false): void {
    const category = this.consentData.categories.find(category => category.items.find(item => item.tag === tag))

    if (category != null) {
      category.accept = granted
      this.saveDataInStorages()
    }
  }

  /**
   * Public API method: update cookie consent state
   */
  updateConsentState(consent: ChangeAgreementEventData, saveInStorage = false): void {
    const tempCategory = this.consentData.categories.find(category => category.id === consent.id)

    if (tempCategory != null) {
      tempCategory.accept = consent.value
    }

    if (saveInStorage) {
      this.saveDataInStorages()
    }
  }

  /**
   * Public API method: accept all consents
   */
  acceptAllConsents(): void {
    this.consentData.categories.forEach((category: CookieConsentCategory) => {
      category.accept = true
    })

    this.saveDataInStorages()
    this.consentModalService.hideModal()
  }

  /**
   * Public API method: save current consents
   */
  saveConsents(): void {
    this.saveDataInStorages()
    this.consentModalService.hideModal()
  }

  /**
   * Public API method: decline all consents without required
   */
  declineAllConsents(): void {
    this.consentData.categories.forEach(category => {
      category.accept = Boolean(category.required)
    })

    this.saveDataInStorages()
    this.consentModalService.hideModal()
  }

  /**
   * Public API method: revert consents setup to storage data
   */
  revertConsentsToStorageData(): void {
    this.consentData = this.mergeApiDataWithSavedInStorageData(this.getNormalizeConsentData(this.apiConsentData))
    this.updateConsentData()
  }

  /** Public API method: check if local data is older than remote data  */
  isLocalDataOlderThanRemoteData(): boolean {
    return this.areConsentsOutOfSync()
  }

  private getFilteredConsentData(data: Readonly<ApiCookieConsent>): ApiCookieConsent {
    const filteredData = JSON.parse(JSON.stringify(data))

    return filteredData
  }

  private prepareForGtagIntegrationIfEnabled(): void {
    if (this.appConfigService.getIntegrationWithGTM()) {
      this.gtagConsentService.prepareForIntegration()
    }
  }

  private areConsentsOutOfSync(): boolean {
    const localSavedData = this.getLocalSavedData()

    if (localSavedData == null) {
      return true
    }

    const isUnequal = this.areCategoriesOutOfSync(localSavedData, this.normalizedConsentData)

    return isUnequal
  }

  private areCategoriesOutOfSync(localSavedData: ConsentStorageData, normalizedApiData: CookieConsent): boolean {
    const localCategories = localSavedData.approval.map(category => ({
      id: category.id,
      items: category.items
    }))

    if (localCategories.length === 0) {
      return true
    }

    const apiCategories = normalizedApiData.categories.map(category => {
      return {
        id: category.id,
        items: this.getItemIdsByCategory(category)
      }
    })

    return !areArraysOfObjectsEqual(localCategories, apiCategories)
  }

  private updateGlobalReferenceForExternalScripts(): void {
    this.apiService.updateConsentItems(this.getConsentItems())
  }

  private getConsentItems(): CookieConsentCategory[] {
    return this.consentData.categories
  }

  private saveDataInStorages(): void {
    const parsedDataForSave: ConsentStorageData = this.prepareDataForSaveSettings(this.consentData)

    this.consentStorageService.saveLocalData(parsedDataForSave)

    this.consentRequestService.sendConfigToApi(parsedDataForSave).subscribe({
      next: () => {
        this.domPreparationService.updateDomStructure(this.consentData.categories)

        if (this.consentModalService.getRefreshRequiredState()) {
          location.reload()
        } else {
          this.refreshUserConsentHistory()
        }
      }
    })
  }

  private refreshUserConsentHistory(): void {
    const history = this.consentStorageService.getLocalSavedData()?.history

    if (history != null) {
      this.consentData = this.consentHistoryService.appendHistoryToItems(history, this.consentData)
      this.updateConsentData()
    }
  }

  private mergeApiDataWithSavedInStorageData(data: CookieConsent): CookieConsent {
    const storageData: ConsentStorageData | null = this.getLocalSavedData()

    if (storageData === null) {
      return data
    }

    if (storageData.user !== undefined) {
      this.userService.updateUserData(storageData.user)
    }

    if (storageData.history !== undefined) {
      data = this.consentHistoryService.appendHistoryToItems(storageData.history, data)
    }

    data.categories.forEach((category: CookieConsentCategory) => {
      const tempApproval = storageData.approval.find(
        (approval: ConsentStorageDataApproval) => approval.id === category.id
      )

      if (tempApproval != null) {
        category.accept = tempApproval.value
      }
    })

    return data
  }

  private getNormalizeConsentData(data: ApiCookieConsent): CookieConsent {
    const cookieConsentRaw = deepCopy<ApiCookieConsent>(data)

    const cookieConsent: CookieConsent = {
      ...cookieConsentRaw,
      categories: cookieConsentRaw.categories.map(category => ({
        ...category,
        accept: Boolean(category.required),
        history: [],
        extend: false
      }))
    }

    return cookieConsent
  }

  private unifyConsentItemsRequiring(data: CookieConsent): CookieConsent {
    const unifiedConsents = deepCopy<CookieConsent>(data)

    return {
      ...unifiedConsents,
      categories: unifiedConsents.categories.map(category => {
        category.required = Boolean(category.required)

        return category
      })
    }
  }

  private updateConsentData(): void {
    this.consentData$.next(this.consentData)
    this.updateGlobalReferenceForExternalScripts()
    this.appEventService.emitConsentChangeEvent()
  }

  private getLocalSavedData(): ConsentStorageData | null {
    return this.consentStorageService.getLocalSavedData()
  }

  private prepareDataForSaveSettings(data: CookieConsent): ConsentStorageData {
    const currentLocalData = this.consentStorageService.getLocalSavedData()
    let history: CookieConsentHistory[] = []

    if (currentLocalData?.history !== undefined) {
      const currentHistory = currentLocalData.history
      history = this.consentHistoryService.updateHistory(this.consentData, currentHistory)
    }

    const storageData: ConsentStorageData = {
      approval: [],
      user: this.userService.getUser(),
      history
    }

    data.categories.forEach((category: CookieConsentCategory) => {
      storageData.approval.push({
        id: category.id,
        value: category.accept ? category.accept : false,
        items: this.getItemIdsByCategory(category)
      })
    })

    return storageData
  }

  private getItemIdsByCategory(category: CookieConsentCategory): string[] {
    return category.items.map(item => item.id)
  }
}
