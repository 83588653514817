export enum GoogleConsentType {
  ad_storage = 'ad_storage',
  ad_user_data = 'ad_user_data',
  ad_personalization = 'ad_personalization',
  analytics_storage = 'analytics_storage',
  functionality_storage = 'functionality_storage',
  personalization_storage = 'personalization_storage',
  security_storage = 'security_storage'
}

export enum CMCategoriesProvidedForGTM {
  marketing = 'marketing',
  statistics = 'statistics',
  preferences = 'preferences',
  necessary = 'necessary'
}

export type GtagConsentState = 'granted' | 'denied'

type GtagConsentsFields = {
  [key in GoogleConsentType]: GtagConsentState
}

export interface GtagConsents extends GtagConsentsFields {
  wait_for_update?: number
}
