import { Component } from 'app/core/base-component'
import { ConsentModalService } from 'app/services/consent-modal.service'
import { ConsentTriggerTemplate } from './consent-trigger.template'
import { ConsentTriggerEvent } from './consent-trigger.model'
import { AppConfigService } from 'app/services/app-config.service'

export class ConsentTrigger extends Component {
  private readonly templateRef: ConsentTriggerTemplate
  private readonly consentModalService: ConsentModalService
  private readonly appConfigService: AppConfigService
  private customCssClassName = ''

  constructor() {
    super({
      componentName: 'consent-trigger',
      componentClass: 'consent-modal2__trigger'
    })

    this.templateRef = new ConsentTriggerTemplate()
    this.consentModalService = ConsentModalService.getInstance()
    this.appConfigService = AppConfigService.getInstance()

    this.init()
  }

  /**
   * Public API method: append widget to document body
   */
  renderWidget(target: HTMLElement = document.body): void {
    this.hostView.setAttribute('data-floating-button', '10')
    target.appendChild(this.hostView)
  }

  private init(): void {
    this.customCssClassName = this.appConfigService.getCustomCssClass()

    if (this.customCssClassName?.length > 0) {
      this.addHostViewClass(this.customCssClassName)
    }

    this.renderContent(this.templateRef.getTemplate())
    this.addEventsSubscriptions()
  }

  private addEventsSubscriptions(): void {
    this.templateRef.events[ConsentTriggerEvent.Show].subscribe(() => {
      this.consentModalService.showModal()
    })
  }
}
