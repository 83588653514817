export const isHtmlContainText = (htmlString: string): boolean => {
  if (typeof htmlString !== 'string') {
    return false
  }

  const textContent: string | null = new DOMParser().parseFromString(htmlString, 'text/html').documentElement
    .textContent

  if (textContent != null && textContent.length > 0) {
    return true
  }

  return false
}
