import { GoogleConsentType, type GtagConsentState, type GtagConsents } from 'app/models/gtag.model'
import { type CookieConsent, type CookieConsentCategory } from '../models/consent-data.model'
import { areObjectsEqual } from 'app/helpers/are-object-equal'
import { CMCategoriesProvidedForGTM } from '../models/gtag.model'

declare global {
  interface Window {
    gtag: unknown
    dataLayer: object[]
  }
}

export class GtagConsentService {
  private static instance: GtagConsentService
  private currentConsentsState = this.getInitialGtagConsents()

  static getInstance(): GtagConsentService {
    if (GtagConsentService.instance === undefined) {
      GtagConsentService.instance = new GtagConsentService()
    }

    return GtagConsentService.instance
  }

  prepareForIntegration(): void {
    window.dataLayer = window.dataLayer ?? []
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }

    window.gtag('consent', 'default', this.getInitialGtagConsents())
  }

  refreshGoogleConsents(cookieConsentData: CookieConsent): void {
    if (!this.isGtagExist()) {
      return
    }

    this.updateConsentsInGtag(cookieConsentData.categories)
  }

  isGtagExist(): boolean {
    if (window.gtag === undefined) {
      return false
    }

    return true
  }

  convertCookieConsentsToGtagConsents(cookieConsentCategories: CookieConsentCategory[]): GtagConsents {
    const gtagConsents = this.getInitialGtagConsents()

    cookieConsentCategories.forEach(category => {
      const state: GtagConsentState = category.accept ? 'granted' : 'denied'

      switch (category.tag as CMCategoriesProvidedForGTM) {
        case CMCategoriesProvidedForGTM.marketing:
          gtagConsents.ad_user_data = state
          gtagConsents.ad_personalization = state
          gtagConsents.ad_storage = state
          break
        case CMCategoriesProvidedForGTM.statistics:
          gtagConsents.analytics_storage = state
          break
        case CMCategoriesProvidedForGTM.preferences:
          gtagConsents.functionality_storage = state
          gtagConsents.personalization_storage = state
          break
        case CMCategoriesProvidedForGTM.necessary:
          gtagConsents.security_storage = state
          break
      }
    })

    return gtagConsents
  }

  private makeGtagConsentsUpdate(gtagConsents: GtagConsents): void {
    if (!this.isGtagExist()) {
      throw new Error('Cookie Info Library: Gtag is unreachable')
    }

    if (
      !areObjectsEqual(
        gtagConsents as unknown as Record<string, unknown>,
        this.currentConsentsState as unknown as Record<string, unknown>
      )
    ) {
      this.currentConsentsState = gtagConsents
      window.gtag('consent', 'update', this.currentConsentsState)
    }
  }

  private getInitialGtagConsents(): GtagConsents {
    const gtagConsents = this.convertConsentEnumToConsentObject('denied')

    // set security_storage as granted on initial consent update in gtag
    gtagConsents.security_storage = 'granted'

    return gtagConsents
  }

  private updateConsentsInGtag(consentCategories: CookieConsentCategory[]): void {
    const gtagConsents: GtagConsents = this.convertCookieConsentsToGtagConsents(consentCategories)
    this.makeGtagConsentsUpdate(gtagConsents)
  }

  private convertConsentEnumToConsentObject(initialState: GtagConsentState): GtagConsents {
    const arrayOfConsents = Object.values(GoogleConsentType).map(value => [value, initialState])
    const objectOfConsents = Object.fromEntries(arrayOfConsents)

    return objectOfConsents
  }
}
