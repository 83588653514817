// Override document.write/ln API due to lack of compatibility
// with the cookie consents mechanism (white page after execution)

import { InternalMessages } from 'app/config/internal-messages'

export const initDocumentWriteDisable = (): void => {
  window.document.write = () => {
    console.error(InternalMessages.WriteMethodDisableInfo)
  }
  window.document.writeln = () => {
    console.error(InternalMessages.WritelnMethodDisableInfo)
  }
}
