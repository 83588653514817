import { html, type TemplateResult } from 'lit-html'
import { live } from 'lit-html/directives/live.js'
import { Subject } from 'rxjs'

import {
  type CookieConsentItem,
  type CookieConsent,
  type CookieConsentCategory,
  type CookieConsentDetailsType,
  type CookieConsentCategoryHistory
} from 'app/models/consent-data.model'
import { BaseTemplate } from 'app/core/base-template'
import { ConsentDetailsEvents } from './consent-details.model'
import { isHtmlContainText } from 'app/helpers/is-html-contain-text'
import { AppLabels } from 'app/models/app-translations.model'

export class ConsentDetailsTemplate extends BaseTemplate<CookieConsent, ConsentDetailsEvents> {
  private categoryTemplates: TemplateResult[] = []
  private changeAgreement$!: Subject<unknown>
  private changeCategoryExpandState$!: Subject<unknown>

  constructor() {
    super()
    this.registerEvents()
  }

  getTemplate(data: CookieConsent): TemplateResult {
    this.categoryTemplates = []

    data.categories.forEach((category: CookieConsentCategory) => {
      if (category.items.length > 0) {
        this.categoryTemplates.push(html`${this.getCategoryTemplate(category)}`)
      }
    })

    return html` ${this.getDetailsDescription(data.details_description)} ${this.categoryTemplates} `
  }

  private onChange(event: Event): void {
    const sourceElement: HTMLInputElement = event.target as HTMLInputElement

    this.changeAgreement$.next({
      id: sourceElement.dataset.id ?? '',
      value: sourceElement.checked
    })
  }

  private registerEvents(): void {
    this.registerEvent(ConsentDetailsEvents.ChangeAgreement, (this.changeAgreement$ = new Subject()))
    this.registerEvent(
      ConsentDetailsEvents.ChangeCategoryExpandState,
      (this.changeCategoryExpandState$ = new Subject())
    )
  }

  private getCategoryTemplate(category: CookieConsentCategory): TemplateResult {
    return html`
      <div class="consent-modal2__details-section">
        <div class="consent-modal2__details-category">
          <div>${this.getCategoryHeaderTemplate(category)}</div>
        </div>

        <div class="consent-modal2__items-wrap${category.extend ? ' consent-modal2__items-wrap--extend' : ''}">
          <div>
            <div class="consent-modal2__category-desc consent-modal2__wysiwyg" .innerHTML=${category.description}></div>

            ${category.items.length > 0
              ? category.items.map((item: CookieConsentItem) => {
                  return this.getItemTemplate(item)
                })
              : ''}

            <div>${this.getHistoryTemplate(category)}</div>
          </div>
        </div>
      </div>
    `
  }

  private getCategoryHeaderTemplate(category: CookieConsentCategory): TemplateResult {
    return html`
      <div class="consent-modal2__category-header-wrap">
        <h3 class="consent-modal2__category-header">${category.name}</h3>
        <div class="consent-modal2__category-options">
          <div>
            <label class="switch2 ${category.required ? 'disabled' : ''}">
              <input
                type="checkbox"
                class="switch2__original-input"
                @change="${this.onChange.bind(this)}"
                data-id="${category.id}"
                .checked=${live(category.required ? true : category.accept)}
                .disabled="${category.required}"
              />
              <span class="switch2__slider"></span>
            </label>
          </div>
          <a
            class="consent-modal2__category-details-toggle${category.extend
              ? ' consent-modal2__category-details-toggle--active'
              : ''}"
            @click=${() => {
              this.changeCategoryExpandState(category.id)
            }}
          ></a>
        </div>
      </div>
    `
  }

  private changeCategoryExpandState(id: string): void {
    this.changeCategoryExpandState$.next(id)
  }

  private getDetailsDescription(htmlString: string): TemplateResult {
    if (isHtmlContainText(htmlString)) {
      return html` <div class="consent-modal2__details-desc consent-modal2__wysiwyg" .innerHTML=${htmlString}></div> `
    }

    return html``
  }

  private getItemFieldTemplate(name: CookieConsentDetailsType, field: string): TemplateResult {
    if (field?.length > 0) {
      const template = html`
        <div class="consent-modal2__item-property">
          <h5 class="consent-modal2__item-property-name">${this.translate(name)}</h5>
          <div class="consent-modal2__item-property-desc consent-modal2__wysiwyg" .innerHTML=${field}></div>
        </div>
      `

      return template
    }

    return html``
  }

  private getItemTemplate(item: CookieConsentItem): TemplateResult {
    if (item.name === '') {
      return html``
    }

    return html`
      <div class="consent-modal2__details-data" id="consent-detail-${item.id}">
        <div>
          <div class="consent-modal2__item-data">
            <h4 class="consent-modal2__item-name">${item.name}</h4>
          </div>
          <div class="consent-modal2__item-property-desc consent-modal2__wysiwyg" .innerHTML=${item.description}></div>
        </div>

        <div class="consent-modal2__item-details">${this.getItemsFieldTemplates(item)}</div>
      </div>
    `
  }

  private getItemsFieldTemplates(item: CookieConsentItem): TemplateResult {
    const excludeFields: string[] = ['id', 'category', 'description', 'tag', 'name']
    const fields = Object.keys(item).filter((field: string) => !excludeFields.includes(field))

    const templates: TemplateResult[] = []

    fields.forEach((field: string) => {
      templates.push(html`${this.getItemFieldTemplate(field as CookieConsentDetailsType, item[field as keyof object])}`)
    })

    return html`${templates}`
  }

  private getHistoryTemplate(category: CookieConsentCategory): TemplateResult {
    if (category.history?.length === 0 || category.required) {
      return html``
    }

    const itemTemplates: TemplateResult[] = []

    category.history.forEach((historyItem: CookieConsentCategoryHistory) =>
      itemTemplates.push(this.getHistoryItemTemplate(historyItem))
    )

    return html`
      <div class="consent-modal2__history-wrap">
        <h5 class="consent-modal2__item-history">${this.translate(AppLabels.DetailHistoryTitle)}</h5>
        ${itemTemplates}
      </div>
    `
  }

  private getHistoryItemTemplate(historyItem: CookieConsentCategoryHistory): TemplateResult {
    return html`
      <div class="consent-modal2__item-history-wrap">
        <div class="consent-modal2__item-history-state">
          <div class="consent-modal2__item-history-state-icon ${historyItem.approval.value ? 'active' : ''}"></div>
          ${historyItem.approval.value
            ? this.translate(AppLabels.HistoryItemAccepted)
            : this.translate(AppLabels.HistoryItemRefused)}
        </div>
        <div>
          <p class="consent-modal2__item-history-date">${new Date(historyItem.date).toLocaleString()}</p>
        </div>
      </div>
    `
  }
}
