import { InternalMessages } from 'app/config/internal-messages'
import axios from 'axios'
import { type Observable, Subject } from 'rxjs'

import { type ConsentStorageData } from '../models/consent-data.model'
import { AppConfigService } from './app-config.service'

export class ConsentRequestService {
  private static instance: ConsentRequestService
  private readonly appConfigService: AppConfigService

  private constructor() {
    this.appConfigService = AppConfigService.getInstance()
  }

  static getInstance(): ConsentRequestService {
    if (ConsentRequestService.instance === undefined) {
      ConsentRequestService.instance = new ConsentRequestService()
    }

    return ConsentRequestService.instance
  }

  /**
   * Public API method: send config data to server's side API
   */
  sendConfigToApi(data: ConsentStorageData): Observable<Date> {
    const respond$ = new Subject<Date>()

    const postData = new URLSearchParams({
      uuid: encodeURIComponent(data.user.uuid),
      'widget-command': this.appConfigService.getWidgetCommand(),
      settings: JSON.stringify(data.approval),
      langCode: this.getCurrentLangCode()
    })

    axios
      .request({
        method: 'POST',
        url: this.appConfigService.getApiUrl(),
        data: postData,
        timeout: 5000,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-type': 'application/x-www-form-urlencoded'
        }
      })
      .then(() => {
        respond$.next(new Date())
      })
      .catch(error => {
        respond$.next(new Date())
        console.error(InternalMessages.SendConsentsLogToApiError, error.message)
      })

    return respond$
  }

  private getCurrentLangCode(): string {
    return this.appConfigService.getInterfaceLang().toLowerCase()
  }
}
