export const initNodeListForeachPolyfill = (): void => {
  if ('NodeList' in window && typeof NodeList.prototype.forEach !== 'function') {
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg !== undefined ? thisArg : window
      for (let i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this)
      }
    }
  }
}
