import { AppCustomEvent } from '../models/app-event.model'

export class AppEventService {
  private static instance: AppEventService

  static getInstance(): AppEventService {
    if (AppEventService.instance === undefined) {
      AppEventService.instance = new AppEventService()
    }

    return AppEventService.instance
  }

  /**
   * Public API method: emit custom event cookie consent change
   */
  emitConsentChangeEvent(): void {
    const event: CustomEvent = new CustomEvent(AppCustomEvent.CookieConsentChange)
    document.dispatchEvent(event)
  }
}
