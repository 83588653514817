import { ConsentAltBoxTemplate } from './consent-alt-box.template'
import { ConsentAltBoxEvents, type ConsentBoxAltType } from './consent-alt-box.model'
import { Component } from 'app/core/base-component'
import { ConsentModalService } from 'app/services/consent-modal.service'
import { ConsentWrapperService } from 'app/services/consent-wrapper.service'
import { ConsentDataService } from 'app/services/consent-data.service'
import { ConsentWrapperStage } from 'app/models/consent-wrapper.model'

export class ConsentAltBox extends Component {
  private readonly templateRef: ConsentAltBoxTemplate
  private readonly consentType: ConsentBoxAltType
  private readonly consentModalService: ConsentModalService
  private readonly consentWrapperService: ConsentWrapperService
  private readonly consentDataService: ConsentDataService

  constructor(consentType: ConsentBoxAltType) {
    super({
      componentName: 'consent-alt-box',
      componentClass: 'cookie-alt'
    })

    this.consentType = consentType
    this.templateRef = new ConsentAltBoxTemplate()
    this.consentModalService = ConsentModalService.getInstance()
    this.consentWrapperService = ConsentWrapperService.getInstance()
    this.consentDataService = ConsentDataService.getInstance()
    this.init()
  }

  /**
   * Public API method: return HTMLElement of component
   */
  getHTMLContent(): HTMLElement {
    return this.hostView
  }

  private init(): void {
    this.renderContent(this.templateRef.getTemplate({ consentType: this.consentType }))

    this.addTemplateEventsSubscriptions()
  }

  private addTemplateEventsSubscriptions(): void {
    this.templateRef.events[ConsentAltBoxEvents.LearnMoreClick].subscribe(() => {
      this.showModalWitchConsentDetails()
    })

    this.templateRef.events[ConsentAltBoxEvents.RunClick].subscribe(consentType => {
      this.consentDataService.updateConsentStateByItemTag(consentType as string, true)
      this.renderContent(
        this.templateRef.getTemplate({
          consentType: this.consentType,
          loadingState: true
        })
      )
    })
  }

  private showModalWitchConsentDetails(): void {
    this.consentWrapperService.switchStage(ConsentWrapperStage.Details)
    this.consentModalService.showModal()
  }
}
