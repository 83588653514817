import { type TemplateResult, html, nothing } from 'lit-html'
import { Subject } from 'rxjs'

import { BaseTemplate } from 'app/core/base-template'
import { ConsentModalEvent } from './consent-modal.model'

interface TemplateData {
  displayCloseButton: boolean
}

export class ConsentModalTemplate extends BaseTemplate<TemplateData, ConsentModalEvent> {
  private readonly closeModal$: Subject<unknown>

  constructor() {
    super()
    this.registerEvent(ConsentModalEvent.CloseModal, (this.closeModal$ = new Subject()))
  }

  getTemplate(data: TemplateData): TemplateResult {
    return html`
      <div class="consent-modal2__content" data-container="content">
        ${data.displayCloseButton
          ? html`<div @click="${this.emitCloseModalEvent.bind(this)}" class="consent-modal2__close"></div>`
          : nothing}
      </div>
    `
  }

  private emitCloseModalEvent(): void {
    this.closeModal$.next(new Date())
  }
}
