import { type ConsentStorageData } from '../models/consent-data.model'

export class ConsentStorageService {
  private static instance: ConsentStorageService

  private readonly storageKey = 'cookie_consent2'

  static getInstance(): ConsentStorageService {
    if (ConsentStorageService.instance === undefined) {
      ConsentStorageService.instance = new ConsentStorageService()
    }

    return ConsentStorageService.instance
  }

  /**
   * Public API method: get consent data from client storage
   */
  getLocalSavedData(): ConsentStorageData | null {
    return this.getDataFromStorage()
  }

  /**
   * Public API method: save consent data in client storage
   */
  saveLocalData(data: ConsentStorageData): void {
    this.saveDataInStorage(data)
  }

  private saveDataInStorage(data: ConsentStorageData): void {
    localStorage.setItem(this.storageKey, JSON.stringify(data))
  }

  private getDataFromStorage(): ConsentStorageData | null {
    try {
      const localDataStorage = localStorage.getItem(this.storageKey) ?? null

      return localDataStorage != null ? JSON.parse(localDataStorage) : null
    } catch (_) {
      return null
    }
  }
}
