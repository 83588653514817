import { type User } from './user.model'

// Interface for structure fetched from backend
export interface ApiCookieConsentCategory {
  id: string
  name: string
  tag: string
  description: string
  required: boolean
  items: ApiCookieConsentItem[]
}

export interface ApiCookieConsentItem {
  attributes: string
  category: string
  collected: string
  company: string
  data_purposes: string
  description: string
  distribution: string
  duration: string
  further_information: string
  id: string
  legal_basis: string
  location_of_processing: string
  name: string
  protection_officer: string
  recipients: string
  technologies: string
  tag: string
}

export interface ApiCookieConsent {
  private_policy: string
  details_description: string
  categories: ApiCookieConsentCategory[]
}

// Interfaces for structure used withing library

export interface CookieConsentCategoryHistory {
  date: Date
  approval: ConsentStorageDataApproval
}

export interface CookieConsentCategory extends Omit<ApiCookieConsentCategory, 'items'> {
  items: CookieConsentItem[]
  accept: boolean
  history: CookieConsentCategoryHistory[]
  extend: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CookieConsentItem extends ApiCookieConsentItem {}

export enum CookieConsentDetailsType {
  Attributes = 'attributes',
  Collected = 'collected',
  Company = 'company',
  DataPurposes = 'data_purposes',
  Distribution = 'distribution',
  Duration = 'duration',
  FurtherInformation = 'further_information',
  LegalBasis = 'legal_basis',
  LocationOfProcessing = 'location_of_processing',
  Name = 'name',
  ProtectionOfficer = 'protection_officer',
  Recipients = 'recipients',
  Technologies = 'technologies'
}

export interface CookieConsent {
  private_policy: string
  details_description: string
  categories: CookieConsentCategory[]
}

export interface CookieConsentHistory {
  date: Date
  id?: string
  ip?: string
  settings: ConsentStorageDataApproval[]
  url: string
  uuid?: string
}

export interface CookieConsentHistoryResponse {
  code: number
  data: CookieConsentHistory[]
}

export interface ConsentStorageDataApproval {
  id: string
  value: boolean
  items?: string[]
}

export interface ConsentStorageData {
  user: User
  approval: ConsentStorageDataApproval[]
  history: CookieConsentHistory[]
}
