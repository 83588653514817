import { BehaviorSubject } from 'rxjs'
import { ConsentWrapperStage } from '../models/consent-wrapper.model'

export class ConsentWrapperService {
  currentStage$: BehaviorSubject<ConsentWrapperStage>

  private static instance: ConsentWrapperService

  private constructor() {
    this.currentStage$ = new BehaviorSubject(ConsentWrapperStage.General as ConsentWrapperStage)
  }

  static getInstance(): ConsentWrapperService {
    if (ConsentWrapperService.instance === undefined) {
      ConsentWrapperService.instance = new ConsentWrapperService()
    }

    return ConsentWrapperService.instance
  }

  /**
   * Public API method: switch stage of consent
   */
  switchStage(stage: ConsentWrapperStage): void {
    this.currentStage$.next(stage)
  }
}
