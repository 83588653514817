import { filter } from 'rxjs'

import { Component } from 'app/core/base-component'
import { ConsentDataService } from 'app/services/consent-data.service'
import { ComponentType } from 'app/core/models/component.model'
import { ConsentModalService } from 'app/services/consent-modal.service'
import { Modal } from '../consent-modal/consent-modal.component'
import { ConsentWrapper } from '../consent-wrapper/consent-wrapper.component'
import { ConsentTrigger } from '../consent-trigger/consent-trigger.component'
import { GtagConsentService } from 'app/services/gtag-consent.service'
import { AppConfigService } from 'app/services/app-config.service'

import { type ApiCookieConsent } from 'app/models/consent-data.model'
import { type CookieConsent } from '../../models/consent-data.model'

export class MainComponent extends Component {
  private modalRef!: Modal
  private readonly consentDataService: ConsentDataService
  private readonly consentModalService: ConsentModalService
  private readonly gtagConsentService: GtagConsentService
  private readonly appConfigService: AppConfigService

  constructor() {
    super({
      componentName: 'cookie-consent-app2',
      componentType: ComponentType.Static
    })

    this.consentDataService = ConsentDataService.getInstance()
    this.consentModalService = ConsentModalService.getInstance()
    this.gtagConsentService = GtagConsentService.getInstance()
    this.appConfigService = AppConfigService.getInstance()
  }

  initApp(): void {
    const cookieConsentDataFromApi: ApiCookieConsent | null = this.getCookieConsentApiData()

    if (cookieConsentDataFromApi != null) {
      this.consentDataService.setInitialCookieConsentData(cookieConsentDataFromApi)
      this.init()
    }
  }

  private init(): void {
    if (this.isPermissionsNeed()) {
      this.displayConsent()
    }

    if (this.appConfigService.getDisplayStickyTriggerState()) {
      this.addTriggerWidget()
    }

    this.addSubscriptions()
  }

  private addTriggerWidget(): void {
    const consentTriggerRef: ConsentTrigger = new ConsentTrigger()
    consentTriggerRef.renderWidget()
  }

  private addSubscriptions(): void {
    this.addSubscriptionForModalStateChanges()

    if (this.appConfigService.getIntegrationWithGTM()) {
      this.addSubscriptionForCookieConsentDataChanges()
    }
  }

  private addSubscriptionForModalStateChanges(): void {
    this.consentModalService.showModal$.subscribe(() => {
      if (this.modalRef === undefined) {
        this.displayConsent()
      } else {
        this.modalRef.show()
      }
    })
  }

  private addSubscriptionForCookieConsentDataChanges(): void {
    this.consentDataService.consentData$
      .pipe(filter((data): data is CookieConsent => data !== null))
      .subscribe(data => {
        this.gtagConsentService.refreshGoogleConsents(data)
      })
  }

  private isPermissionsNeed(): boolean {
    return this.consentDataService.isLocalDataOlderThanRemoteData()
  }

  private displayConsent(): void {
    const consentWrapperRef = new ConsentWrapper()

    this.modalRef = new Modal()
    this.modalRef.setContent(consentWrapperRef.hostView)
    this.appendChild(this.modalRef.hostView)

    this.modalRef.show()
  }

  private getCookieConsentApiData(): ApiCookieConsent | null {
    try {
      return this.hostView.dataset.cookieContent != null ? JSON.parse(this.hostView.dataset.cookieContent) : null
    } catch (_) {
      return null
    }
  }
}
