interface CustomEventParams {
  bubbles?: boolean | undefined
  cancelable?: boolean | undefined
  detail?: unknown
}

export const customEventPolyfill = (): void => {
  if (typeof window.CustomEvent === 'function') return

  function CustomEvent(event: string, params?: CustomEventParams): CustomEvent {
    if (params === undefined) {
      params = { bubbles: false, cancelable: false, detail: null }
    }

    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.CustomEvent = CustomEvent
}
