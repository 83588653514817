export function areArraysEqual(array1: string[] | null, array2: string[] | null): boolean {
  if (array1 == null || array2 == null) {
    return false
  }

  if (array1.length === array2.length) {
    return array1.every(element => array2.includes(element))
  }

  return false
}

export function areObjectsEqual<T extends Record<string, unknown>>(first: T, second: T): boolean {
  const firstKeys = Object.keys(first)
  const secondKeys = Object.keys(second)

  if (firstKeys.length !== secondKeys.length) {
    return false
  }

  for (const key of firstKeys) {
    if (!Object.prototype.hasOwnProperty.call(second, key) || first[key] !== second[key]) {
      return false
    }
  }

  return true
}

export function areArraysOfObjectsEqual(first: object[] | null, second: object[] | null): boolean {
  if (first == null || second == null) {
    return false
  }

  if (first.length !== second.length) {
    return false
  }

  const notFoundCorrespondingElement = first.some(firstElement => {
    const secondElement = second.find(
      secondItem => secondItem['id' as keyof object] === firstElement['id' as keyof object]
    )

    if (secondElement == null) {
      return true
    }

    if (!areArraysEqual(firstElement['items' as keyof object], secondElement['items' as keyof object])) {
      return true
    }

    return false
  })

  return !notFoundCorrespondingElement
}
