import {
  type ConsentStorageDataApproval,
  type CookieConsent,
  type CookieConsentCategory,
  type CookieConsentHistory
} from '../models/consent-data.model'

export class ConsentHistoryService {
  private static instance: ConsentHistoryService
  private readonly currentHistory: CookieConsentHistory[] = []

  static getInstance(): ConsentHistoryService {
    if (ConsentHistoryService.instance === undefined) {
      ConsentHistoryService.instance = new ConsentHistoryService()
    }

    return ConsentHistoryService.instance
  }

  /**
   * Public API method: append history to consent items
   */
  appendHistoryToItems(history: CookieConsentHistory[], consentsData: CookieConsent): CookieConsent {
    if (history === undefined) {
      return consentsData
    }

    consentsData.categories.forEach((category: CookieConsentCategory) => (category.history = []))

    history.forEach((historyItem: CookieConsentHistory) => {
      const date: Date = historyItem.date
      const settings: ConsentStorageDataApproval[] = historyItem.settings

      settings.forEach((approval: ConsentStorageDataApproval) => {
        const category = consentsData.categories.find(category => category.id === approval.id)

        if (category?.history != null) {
          category.history.push({
            approval,
            date
          })
        }
      })
    })

    return consentsData
  }

  /**
   * Public API method: append history to consent items
   */
  updateHistory(cookieConsentData: CookieConsent, history: CookieConsentHistory[]): CookieConsentHistory[] {
    const historyItem: CookieConsentHistory = {
      date: new Date(),
      settings: this.extractUserDecisions(cookieConsentData),
      url: window.location.href
    }

    history.unshift(historyItem)
    history = history.slice(0, 3)

    return history
  }

  private extractUserDecisions(data: CookieConsent): ConsentStorageDataApproval[] {
    return data.categories.map((category: CookieConsentCategory) => ({
      id: category.id,
      value: category.accept
    }))
  }
}
