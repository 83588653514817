import { Subscription, filter } from 'rxjs'

import { ConsentDetailsTemplate } from './consent-details.template'
import { ConsentDetailsEvents, type ChangeAgreementEventData } from './consent-details.model'
import { Component } from 'app/core/base-component'
import { type CookieConsent } from 'app/models/consent-data.model'
import { ConsentDataService } from 'app/services/consent-data.service'

export class ConsentDetails extends Component {
  private cookieConsentData!: CookieConsent
  private readonly consentDataService: ConsentDataService
  private readonly templateRef: ConsentDetailsTemplate
  private consentSubscription!: Subscription
  private eventSubscription!: Subscription

  constructor() {
    super({
      componentName: 'consent-details',
      componentClass: 'consent-modal2__details'
    })

    this.consentDataService = ConsentDataService.getInstance()
    this.templateRef = new ConsentDetailsTemplate()
    this.addSubscriptions()
  }

  private addEventSubscriptions(): void {
    if (this.eventSubscription === undefined) {
      this.eventSubscription = new Subscription()

      this.eventSubscription.add(
        this.templateRef.events[ConsentDetailsEvents.ChangeCategoryExpandState].subscribe(categoryId => {
          this.toggleCategoryExtendState(categoryId as string)
        })
      )
    }
  }

  private toggleCategoryExtendState(categoryId: string): void {
    const category = this.cookieConsentData.categories.find(category => category.id === categoryId)

    if (category != null) {
      category.extend = !category.extend
    }

    this.renderDetails()
  }

  private addSubscriptions(): void {
    this.consentDataService.consentData$
      .pipe(filter((data): data is CookieConsent => data !== null))
      .subscribe(data => {
        this.cookieConsentData = data
        this.renderDetails()
        if (this.consentSubscription === undefined) {
          this.consentSubscription = this.getRenderDetailsSubscription()
        }
      })
  }

  private renderDetails(): void {
    this.renderContent(this.templateRef.getTemplate(this.cookieConsentData))
    this.addEventSubscriptions()
  }

  private getRenderDetailsSubscription(): Subscription {
    return this.templateRef.events[ConsentDetailsEvents.ChangeAgreement].subscribe(data => {
      this.consentDataService.updateConsentState(data as ChangeAgreementEventData, false)
    })
  }
}
