import { type TemplateEvents, TemplateRef } from './models/template.model'
import { type Subject } from 'rxjs'
import { TranslateService } from '../services/translate.service'
import { TemplateResult, html } from 'lit-html'

export class BaseTemplate<TData, TEvents extends string> implements TemplateRef<TData, TEvents> {
  events: TemplateEvents<TEvents> = {} as TemplateEvents<TEvents>

  registerEvent(key: TEvents, event: Subject<unknown>): void {
    this.events[key] = event
  }

  translate(string: string): string {
    return TranslateService.getInstance().translate(string as keyof object)
  }

  getTemplate(data?: TData): TemplateResult {
    return html`${data} ?? ''`
  }
}
